import React from 'react';
import { Helmet } from 'react-helmet';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Scrollbar } from 'swiper';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/swiper.scss';
import IconStar from '~/components/atoms/IconStar'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'
import CustomCursor from '~/components/atoms/CustomCursor';

export default function Reviews({ key, index, ...props }) {

  SwiperCore.use([Scrollbar]);

  const section = props.reviews;
  const rating = String(section.rating ?? '4,9').replace('.', ',');
  const reviewsData = section.reviewsData;

  // console.log('~~~>>>reviewsData====', reviewsData);

  if (reviewsData == [] || reviewsData == '' || reviewsData == null) {
    return (
      <Helmet>
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org/",
          "@type": "LocalBusiness",
          "name": "City Relay",
          "url": "https://cityrelay.com/",
          "telephone":"+442033186268",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "First Floor, 10 Cromwell Pl, South Kensington",
            "addressLocality": "London",
            "postalCode": "SW7 2JN",
            "addressCountry": "UK"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "itemReviewed": {
              "@type": "Organization",
              "name": "City Relay",
              "url": "https://cityrelay.com/"
            },
            "ratingValue": "${rating}",
            "ratingCount": "${section.count}",
            "reviewCount": "${section.count}",
            "bestRating": "5",
            "worstRating": "1"
          }
        }
      `}
        </script>
      </Helmet>
    );
  }

  // if reviewsData not null
  else {
    return (
      <FadeInWhenVisible id={section.fieldGroupName + index} className="py-28 service-swiper overflow-hidden bg-sand">
        <div className="container">
          <FadeInWhenVisible>
            <h5 className="text-h5 mb-12 text-center">{section.heading}</h5>
          </FadeInWhenVisible>

          <FadeInWhenVisible>
            <div
              className="custom-review"
              data-locale="en-GB"
              data-style-height="250px"
              data-style-width="100%"
              data-theme="light"
              data-tags={section.type}
              data-font-family="Roboto"
              data-text-color="#12203A"
            >
              <div class="swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events swiper-container-free-mode w-full flex flex-col" style={{ overflow: 'visible', cursor: 'grab' }}>
                <CustomCursor content="Drag" className="h-full w-full">
                  <Swiper
                    className="w-full flex flex-col"
                    slidesPerView={`auto`}
                    grabCursor="1"
                    freeMode="1"
                    scrollbar={{ draggable: true }}
                    style={{ overflow: `visible` }}
                  >
                    {reviewsData?.map((review, i) => (
                      <SwiperSlide
                        className="max-w-[80%] mr-8 md:max-w-xl last:mr-0"
                        key={`slide-${i}`}
                        style={{ height: 'auto' }}>
                        <div
                          className="bg-white h-full flex flex-col justify-center px-8 py-12 md:px-28 md:py-16 text-center"
                          style={{ opacity: 1, transform: 'translateY(0vh) translateZ(0px)' }}>
                          <div className="mb-8 flex justify-center space-x-1">
                            {Array.from({ length: review.stars }, (_, index) => (
                              <div className="text-blue" key={index}>
                                <svg
                                  width="16"
                                  height="15"
                                  viewBox="0 0 16 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M7.875 0L10.1101 5.04871L15.6023 5.61424L11.4914 9.30004L12.6508 14.6983L7.875 11.9275L3.09924 14.6983L4.25861 9.30004L0.147666 5.61424L5.63995 5.04871L7.875 0Z"
                                    fill="currentColor"></path>
                                </svg>
                              </div>
                            ))}
                          </div>
                          <p className="mb-8">
                            {review.review}
                          </p>
                          <div className="font-medium"></div>
                          <div></div>
                        </div>
                      </SwiperSlide>
                    ))}

                  </Swiper>
                </CustomCursor>
              </div>
            </div>
          </FadeInWhenVisible>
        </div >

        <Helmet>
          <script type="application/ld+json">
            {`
          {
            "@context": "https://schema.org/",
            "@type": "LocalBusiness",
            "name": "City Relay",
            "url": "https://cityrelay.com/",
            "telephone":"+442033186268",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "First Floor, 10 Cromwell Pl, South Kensington",
              "addressLocality": "London",
              "postalCode": "SW7 2JN",
              "addressCountry": "UK"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "itemReviewed": {
                "@type": "Organization",
                "name": "City Relay",
                "url": "https://cityrelay.com/"
              },
              "ratingValue": "${rating}",
              "ratingCount": "${section.count}",
              "reviewCount": "${section.count}",
              "bestRating": "5",
              "worstRating": "1"
            }
          }
        `}
          </script>
        </Helmet>
      </FadeInWhenVisible >
    );
  }


  // --------------------------------- OLD CODE
  // const ref = React.useRef(null);
  // React.useEffect(() => {
  //   if (window.Trustpilot) {
  //     window.Trustpilot.loadFromElement(ref.current, true);
  //   }
  // }, []);

  /*
  return (
    <FadeInWhenVisible id={section.fieldGroupName + index} className="py-28 service-swiper overflow-hidden bg-sand">
      <div className="container">
        <FadeInWhenVisible>
          <h5 className="text-h5 mb-12 text-center">{section.heading}</h5>
        </FadeInWhenVisible>

        <div id='test'>
          {section.reviews}
        </div>
        { <FadeInWhenVisible>
          <div
            ref={ref}
            className="trustpilot-widget"
            data-locale="en-GB"
            data-template-id="54ad5defc6454f065c28af8b"
            data-businessunit-id="579f04e80000ff000592f5f7"
            data-style-height="250px"
            data-style-width="100%"
            data-theme="light"
            data-tags={section.type}
            data-stars="3,4,5"
            data-review-languages="en"
            data-font-family="Roboto"
            data-text-color="#12203A"
          >
            <a href="https://uk.trustpilot.com/review/cityrelay.com" target="_blank" rel="noopener">See more reviews</a>
          </div>
        </FadeInWhenVisible> }
      </div>
      <Helmet>
        <script type="application/ld+json">
          {`
           {
             "@context": "https://schema.org/",
             "@type": "LocalBusiness",
             "name": "City Relay",
             "url": "https://cityrelay.com/",
             "telephone":"+442033186268",
             "address": {
               "@type": "PostalAddress",
               "streetAddress": "First Floor, 10 Cromwell Pl, South Kensington",
               "addressLocality": "London",
               "postalCode": "SW7 2JN",
               "addressCountry": "UK"
             },
             "aggregateRating": {
               "@type": "AggregateRating",
               "itemReviewed": {
                 "@type": "Organization",
                 "name": "City Relay",
                 "url": "https://cityrelay.com/"
               },
               "ratingValue": "${rating}",
               "ratingCount": "${section.count}",
               "reviewCount": "${section.count}",
               "bestRating": "5",
               "worstRating": "1"
             }
           }
         `}
        </script>
      </Helmet>
    </FadeInWhenVisible>
  );
  */

}
